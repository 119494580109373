import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions, Image, ActivityIndicator, Alert } from 'react-native';
import { useSelector } from 'react-redux'

import axios from 'axios'
import Boleto from './../components/Boleto'

const windowWidth = Dimensions.get('window').width;

const billet = ({route}) => {
    const { placaCarro } = route.params
    const [numBoletos, setNumBoletos] = useState(1);//quantidade de boletos existentes
    const [boletos, setBarCode] = useState([]);
    const [load, setLoad] = useState(true)
  
    const repository = useSelector(state => state)

    function compare( a, b ) {
        if ( a.data_vencimento < b.data_vencimento ){
          return -1;
        }
        if ( a.data_vencimento > b.data_vencimento ){
          return 1;
        }
        return 0;
      }
  
    useEffect(() => {
      
      let l_cpf = repository.associate[0].cpf; // váriavel local
  
          //faz a requisição http
          axios.get('https://gestaogma.com.br/services/segunda-via-boleto/index.php', {
              params: {
                  cpf: l_cpf
              }
          })
          .then( async (response) => {
                let r = JSON.stringify(response);
                let obj = JSON.parse(r);
  
                const json_boletos = obj.data.msg;
              
                //FUNÇÃO PARA PERCORER OS BOLETOS E CHECAR QNTs BOLETOS SÃO VALIDOS
                    let placas = []; 
                    let temp_boletos = [];
                    placas.push(json_boletos[0].placas);
                    let placaTemp = '';
                    let paid = '';
                    let countBoletoPago = 0;
                    let validacao = null;
  
                    let data = new Date();
                    let tempData = '';
                    let tempMonth = '';
                    let tempYear = '';
                    let currentYear = data.getUTCFullYear();
                    let currentMonth = data.getMonth();
  
  
                    //bloco para saber quantos boletos não estão pagos
                    await json_boletos.map((boleto) => {
                        tempData = new Date(boleto.data_vencimento);
                        tempMonth = tempData.getMonth();
                        tempYear = tempData.getUTCFullYear();
  
                        paid = boleto.descricao_situacao
                        if( ( tempMonth == currentMonth ) && ( tempYear == currentYear ) ){
                            countBoletoPago += 1;
                        }

                        if(paid != "BAIXADO" && tempYear == currentYear && boleto.placas == placaCarro){
                            temp_boletos.push(boleto)
                        }

                    });

                    temp_boletos = await temp_boletos.sort(compare)

                    await setBarCode(temp_boletos);
                    
                    console.log(temp_boletos)

                //END FUNCTION
  
  

                
                
                await setLoad(false)
          })
          .catch((error) => {
              Alert.alert('Erro ao obter boleto: ' + JSON.stringify(error))
              let r = JSON.stringify(error)
          });
    },[])
  
    return ( 
      <ScrollView style={styles.container}>
        <View style={styles.header}>
          <Image source={require('./../../assets/billet.png')} style={styles.imageHeader} resizeMode="cover" />
        </View>
  
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Text style={styles.title}>Confira suas informações</Text> 
        </View>
  
        <View style={[styles.row, { borderRadius: 10, borderBottomColor: '#cdcdcd', borderBottomWidth: 1, justifyContent: 'center', alignItems: 'center'}]}>
          <Text> {repository.associate[0].cpf.length > 11 ? 'CNPJ' : 'CPF'}: {repository.associate[0].cpf}</Text>
        </View>
        
        <ActivityIndicator size="large" color="#0000ff" animating={load}/>

            { 
                boletos.map(boleto => {
                    let linha_digitavel = boleto.linha_digitavel
                    let descricao_situacao = boleto.descricao_situacao
                    let placas = boleto.placas
                    let data_vencimento = boleto.data_vencimento 
                    let url = boleto.url
                  
                    if(boleto.placas == placaCarro){
                        return (
                            <Boleto 
                                linha_digitavel={linha_digitavel} 
                                descricao_situacao={descricao_situacao} 
                                placas={placas}
                                data_vencimento={data_vencimento}
                                link={url}
                                key={linha_digitavel}
                            />
                        )
                    }else{
                        return false;
                    }
                    
                })  
            }
      </ScrollView> 
    )
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
    },
    imageHeader: {
        width: windowWidth,
        height: 210,
    },
  
})


export default billet;