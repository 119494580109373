import React from 'react';
import {ScrollView, View, StyleSheet, Text, Dimensions, Image, TouchableOpacity, Linking, Button } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { useSelector } from 'react-redux'

const windowWidth = Dimensions.get('window').width;

const location = () => {
  const repository = useSelector(state => state)
  const text = "Olá! Gostaria de conhecer os benefícios de associado."
  return (
    <ScrollView>
      <View style={styles.container}>
          <View style={styles.header}>
            <Image source={require('./../../assets/partnership.png')} style={styles.imageHeader} resizeMode="cover" />

            <View style={{paddingHorizontal: 30, paddingVertical: 30}}>
              <Text style={{justifyContent: 'center', textAlign: 'center' }}>O Clube de Benefícios RioBen oferece descontos exclusivos em mais de 275 estabelecimentos para seus associados que fizerem parte do clube.</Text>
            </View>

            <TouchableOpacity style={styles.button} onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521998022453`)}>
                <View style={styles.row}> 
                    <Image source={require('./../../assets/netshoes-logo].png')} style={{width: 115, height: 68}} resizeMode="cover"/>
                    <View style={{marginLeft: 20}}> 
                        <Text>{ `PRODUTOS COM ATÉ 15% \nDE DESCONTO. APROVEITE. `}</Text>
                        <Text style={{textAlign: 'center', color: '#fff', fontWeight: 'bold', backgroundColor: '#003b76', marginTop: 8, paddingHorizontal: 10, paddingVertical: 10}}>Clique aqui e saiba mais</Text>
                    </View>
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={styles.button} onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521998022453`)}>
                <View style={styles.row}> 
                    <Image source={require('./../../assets/logo-pacheco.png')} style={{width: 115, height: 68}} resizeMode="cover"/>
                    <View style={{marginLeft: 20}}> 
                        <Text>{ `ATÉ 30% DE DESCONTO EM.\n FARMÁCIAS APROVEITE. `}</Text>
                        <Text style={{textAlign: 'center', color: '#fff', fontWeight: 'bold', backgroundColor: '#003b76', marginTop: 8, paddingHorizontal: 10, paddingVertical: 10}}>Clique aqui e saiba mais</Text>
                    </View>
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={styles.button} onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521998022453`)}>
                <View style={styles.row}> 
                    <Image source={require('./../../assets/logo-mocelin.png')} style={{width: 115, height: 68}} resizeMode="cover"/>
                    <View style={{marginLeft: 20}}> 
                        <Text>{ `20% DE DESCONTO. APROVEITE.`}</Text>
                        <Text style={{textAlign: 'center', color: '#fff', fontWeight: 'bold', backgroundColor: '#003b76', marginTop: 8, paddingHorizontal: 10, paddingVertical: 10}}>Clique aqui e saiba mais</Text>
                    </View>
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={styles.button} onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521998022453`)}>
                <View style={styles.row}> 
                    <Image source={require('./../../assets/logo-yes.png')} style={{width: 115, height: 68}} resizeMode="cover"/>
                    <View style={{marginLeft: 20}}> 
                        <Text>{ `50% DE DESCONTO + \nMATRICULA GRÁTIS. APROVEITE. `}</Text>
                        <Text style={{textAlign: 'center', color: '#fff', fontWeight: 'bold', backgroundColor: '#003b76', marginTop: 8, paddingHorizontal: 10, paddingVertical: 10}}>Clique aqui e saiba mais</Text>
                    </View>
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={styles.button} onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521998022453`)}>
                <View style={styles.row}> 
                    <Image source={require('./../../assets/logo-renner.png')} style={{width: 115, height: 68}} resizeMode="cover"/>
                    <View style={{marginLeft: 20}}> 
                        <Text>{ `PRODUTOS COM ATÉ 15% \nDE DESCONTO. APROVEITE. `}</Text>
                        <Text style={{textAlign: 'center', color: '#fff', fontWeight: 'bold', backgroundColor: '#003b76', marginTop: 8, paddingHorizontal: 10, paddingVertical: 10}}>Clique aqui e saiba mais</Text>
                    </View>
                </View>
            </TouchableOpacity>
          </View>

          <TouchableOpacity onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521998022453`)}>
            <View style={{backgroundColor: '#003b76', justifyContent: 'center', alignItems: 'center', padding: 12, marginBottom: 20, borderRadius: 10, width: 260, alignSelf: 'center', justifySelf: 'center'}}>
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: "center"}}>E muito mais, clique aqui e confira.</Text>  
            </View> 
          </TouchableOpacity>
      </View> 
    </ScrollView> 
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  imageHeader: {
    width: windowWidth,
    height: 210,
  },
  button: {
    marginBottom: 30
  },
  row: {
    width: windowWidth, 
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20, 
    borderBottomWidth: 2,
    borderColor: '#003b76',
    backgroundColor: '#fff',
    paddingTop: 25, 
    paddingBottom: 25
  }
})

export default location;