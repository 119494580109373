import React from 'react';
import PDFReader from 'rn-pdf-reader-js'

const BoletoPdf = ( {route, navigation} ) => {
  const { url } = route.params

  return <PDFReader source={{ uri: url,}}
  />
}

export default BoletoPdf;