const INITIAL_STATE = { 
    associate: { 

    },
    benefits: {
        produtos: [ 
            {

            }
        ]
    }
}

const reducers = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "ADD_ASSOCIATE": 
            return { ...state, associate: action.associate }
        case "ADD_BENEFITS":
            return { ...state, benefits: action.benefits}
        default: 
            return state
    }
}

export default reducers