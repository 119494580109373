import React from 'react';
import {ScrollView, View, ImageBackground, StyleSheet, Text, Image, TouchableOpacity, Linking, Button} from 'react-native';

import {useNavigation} from '@react-navigation/native'
import {useSelector} from 'react-redux'
import {
    useFonts,
    Anton_400Regular 
  } from '@expo-google-fonts/anton'

const home = () => {
    useFonts({
        'Anton_400Regular': require('./../../assets/fonts/Anton.ttf'),
    });

    const navigation = useNavigation()
    const repository = useSelector(state => state)

    const text = "Olá! Gostaria de participar do sorteio do final de semana com hospedagem e viagem."
    return (
        <ScrollView style={styles.container}>
            <ImageBackground style={styles.imageBack} source={require('./../../assets/imageBackgroundInapp.png')}>

                <View style={styles.boxTitle}> 
                    <Text style={[styles.titleName, { marginTop: 40 }]}>{`Seja bem-vindo,`}</Text>
                    <Text style={[ styles.titleName, { fontWeight: 'bold', fontSize: 22, fontFamily: 'Anton_400Regular', color: '#fff', textShadowColor: 'rgba(0, 0, 0, 0.75)', textShadowOffset: {width: -1, height: 1}, textShadowRadius: 5 }]}>{repository.associate[0].nome}</Text>
                </View>

                <TouchableOpacity onPress={() => navigation.navigate('Partnership')}>
                    <View style={styles.wrapperBox}>
                        <View style={styles.box}>
                            <Image source={require('./../../assets/partnership.png')} style={{width: 290, height: 60}} resizeMode="cover" />
                            <Text style={styles.boxTitle}>Veja nossas parcerias e aproveite descontos</Text>
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => Linking.openURL("https://gestaogma.com.br/files/manual_rioben.pdf")}>
                    <View style={styles.wrapperBox}>    
                    <Text style={styles.title}>{`Dúvidas? Baixe agora o manual.`}</Text>
                        <View style={[styles.box, { paddingVertical: 10 }]}>
                            <Image source={require('./../../assets/rioben_logo.png')} style={{width: 290, height: 100}} resizeMode="cover" />
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521998022453`)}>
                    <View style={styles.wrapperBox}>    
                    <Text style={styles.title}>{`Quer ganhar um final de semana com café da manhã e hospedagem em qualquer lugar do Brasil?`}</Text>
                        <View style={{backgroundColor: "#003b76", padding: 20, borderRadius: 10, justifyContent: 'center', marginTop: 30}}>
                            <Text style={{color: "#fff", fontWeight: 'bold', justifyContent: 'center', alignContent: 'center', textAlign: 'center'}}>Clique aqui e escolha a opção 4</Text>
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => navigation.navigate('Cupom')}>
                    <View style={[styles.wrapperBox, { borderWidth: 0, marginBottom: 100 }]}>
                        <Text style={styles.title}>{`Quer ganhar prêmios?\nEntão indique e ganhe!`}</Text>
                        <Image source={require('./../../assets/cupom.png')} style={{width: 290, height: 190}} resizeMode="contain" />
                    </View>
                </TouchableOpacity>
            </ImageBackground>
        </ScrollView>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    imageBack: {
        flex: 1,
        resizeMode: "cover",
        alignItems: "center"
    },
    boxTitle: {
        marginTop: 50
    },
    titleName: { 
        fontSize: 20,
        marginTop: 0,
        textAlign: 'center',
        color: "rgba(255,255,255, 0.9)",
        fontFamily: 'Anton_400Regular'
    },
    title:{ 
        fontSize: 22, 
        fontFamily: 'Anton_400Regular', 
        color: '#003b76', 
        textAlign: 'center',
        fontWeight: 'bold',
        width: 280
    },
    box: {
        padding:0,
        width: 290,

        backgroundColor: "#fff",
        borderRadius: 10,
        elevation: 11,

        overflow: "hidden",

        justifyContent: "center", 
    },
    wrapperBox:{
        marginTop: 80,
        paddingVertical: 18,
        paddingHorizontal: 8,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "#dff9fb"
    },
    boxTitle: { 
        padding: 20,
        fontWeight: "bold",
        textAlign: "center"
    }
})

export default home;