import React from 'react';
import { StyleSheet} from 'react-native'
import WebView from 'react-native-webview'


const novo_portal_rastreador = () => {
    const js = `const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); window.scrollTo(300, 0); `
    return <WebView 
            source={{ uri: 'https://sistema.getrak.com/mma_monitoramento/acesso/index' }} 
            display="flex"
            style={styles.container} 
            injectedJavaScript={js}
        />;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
    }
})

export default novo_portal_rastreador;