import React from 'react';
import { View, StyleSheet, Text, Dimensions, Image, ScrollView, TouchableOpacity, Alert} from 'react-native';

import {useNavigation} from '@react-navigation/native'
import { useSelector } from 'react-redux'

const windowWidth = Dimensions.get('window').width;

const vehicle = () => {
  const navigation = useNavigation()
  const associate = useSelector(state => state.associate)
  const sorted_associate = associate[0].veiculos.sort( (a, b) => { 
    if(a.situacao < b.situacao){
      return -1;
    }else if(a.situacao > b.situacao){
      return 1;
    }else{
      return 0;
    }
  })

  return (
    <ScrollView View style={styles.container}>
      <View style={{justifyContent: "flex-start", alignItems: "center"}}> 
        <View style={styles.header}>
          <Image source={require('./../../assets/veiculos.jpg')} style={styles.imageHeader} resizeMode="cover" />
        </View>

        <View style={styles.main}>
          <Text style={{ textAlign: "center", fontSize: 16}}>{`Você possui os seguintes\nveículos registrados`}</Text>
          {
            sorted_associate.map((car) => {
                return  (
                  <View style={styles.car} key={car.placa}>
                    <TouchableOpacity onPress={()=> { if(car.situacao == "ATIVO"){ return navigation.navigate('Benefits', {placaCarro: car.placa}) }else{Alert.alert('Erro', 'Informação não disponível para esse veículo.'); return false;}}}>
                      <View style={[styles.row, {marginBottom: 20}]}>
                        <Text style={{color: '#0f2f92', fontWeight: 'bold', textAlign: 'center'}}>TOQUE AQUI PARA VER OS SEUS BENEFÍCIOS</Text>
                      </View>
                    </TouchableOpacity>
  
                    <View style={[styles.row, {flexWrap: "wrap"}]}>
                      <Text style={styles.title}>Veículo:</Text>
                      <Text style={styles.valueCar}>{ car.descricao_modelo} </Text> 
                    </View>
        
                    <View style={styles.row}>
                      <Text style={styles.title}>Status: </Text>
                      <Text style={[styles.valueCar, {color: (car.situacao == "ATIVO") ? "#2ecc71" : "#d35400"}]}>{ car.situacao} </Text> 
                    </View>
                    
                    <TouchableOpacity onPress={() => {return navigation.navigate('BoletoIndividual', {placaCarro: car.placa})}}>
                      <View style={styles.placa}>
                        <View style={styles.header}>
                          <Image source={require('./../../assets/mercosul-flag.png')} style={{height: 8, width: 8}} />
                          <Text style={{color: "#fff", textAlign: "center", fontSize: 8}}>Brasil</Text>
                          <Image source={require('./../../assets/brasil-flag.png')} style={{height: 8, width: 8}} />
                        </View>
                        <View style={styles.numbers}>
                          <Text style={{textAlign:'center'}}> { car.placa} </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
  
                    <View style={[styles.row, { marginTop: 20}]}>
                      <Text style={{color: '#d40c0a', fontWeight: 'bold', textAlign: 'center'}}>TOQUE NA PLACA PARA BAIXAR SEU BOLETO</Text>
                    </View>
                  </View>
                )
            })
          }
        </View>
      </View>
    </ScrollView> 
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  imageHeader: {
    width: windowWidth,
    height: 210,
  },
  main: {
    marginTop: 50,
  },
  car: {
    justifyContent: "center", 
    alignItems: "center",
    width: windowWidth - 70,

    marginTop: 30,

    paddingHorizontal: 30,
    paddingVertical: 50,

    backgroundColor: "#fff",
  
    borderRadius: 10,
    borderBottomWidth: 5,
    borderColor: "#003b76",
  },
  row:{
    marginVertical: 5,
    flexDirection: "row",
    alignSelf: "flex-start"
  },
  title: {
    fontWeight: "bold",
    color: "#313131"
  },
  valueCar: {
    color: "#c8c8c8",
    fontWeight: "bold", flexWrap: "wrap"
  },
  placa: { 
    width: 180,
    marginTop: 30,
    borderWidth: 3, 
    borderColor: "#313131",
    borderRadius: 10,
    overflow: "hidden"
  },
  numbers: {
    backgroundColor: "#f1f1f1",
    padding: 8, 
    justifyContent: 'center' 
  },
  header: { 
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: "#1737b0",
    paddingVertical: 0,
    paddingHorizontal: 3,
    justifyContent: 'space-between'
  }
 
})


export default vehicle;