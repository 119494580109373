import React from 'react';

import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

//importando classes (telas)
import BottomNavigator from './BottomNavigator'
import login from './../pages/login'
import blog from './../pages/blog'
import cupom from './../pages/cupom'
import BoletoPdf from './../pages/pageBillet'
import partnership from './../pages/partnership'
import benefits from './../pages/benefits'
import billet from './../pages/billet'
import initialScreen from './../pages/initialScreen'
import quote from './../pages/quote'
import novo_portal_rastreador from '../pages/novo_portal_rastreador';
import older_portal_rastreador from '../pages/older_portal_rastreador';

const Stack = createStackNavigator()

const StackNavigator = () => {
    return(
        <NavigationContainer> 
          <Stack.Navigator >
            <Stack.Screen  name="Initial" component={initialScreen} options={{ title: "Seja Bem-Vindo" }} />
            <Stack.Screen  name="Login" component={login} options={{ title: "Login" }} />
            <Stack.Screen  name="Tab" component={BottomNavigator} options={{ title: "Rio Ben - Proteção Veicular" }} />
            <Stack.Screen  name="Blog" component={blog} options={{ title: "Blog Gestão GMA" }} />
            <Stack.Screen  name="Cupom" component={cupom} options={{ title: "Associado indica" }} />
            <Stack.Screen  name="BoletoPdf" component={BoletoPdf} options={{ title: "Boleto PDF" }} />
            <Stack.Screen  name="Partnership" component={partnership} options={{ title: "Parceiros Rio Ben" }} />
            <Stack.Screen  name="Benefits" component={benefits} options={{ title: "Beneficios Rio Ben" }} />
            <Stack.Screen  name="BoletoIndividual" component={billet} options={{ title: "Seu boleto" }} />
            <Stack.Screen  name="Quote" component={quote} options={{ title: "Solicite sua cotação" }} />
            <Stack.Screen  name="NovoPortalRastreador" component={novo_portal_rastreador} options={{ title: "Rastreie seu veículo" }} />
            <Stack.Screen  name="OlderPortalRastreador" component={older_portal_rastreador} options={{ title: "Rastreie seu veículo" }} />
          </Stack.Navigator>
        </NavigationContainer>
    )
}

export default StackNavigator;