import React from 'react';
import {ScrollView, View, StyleSheet, Text, Dimensions, Image, TouchableOpacity, Linking } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import {useNavigation} from '@react-navigation/native'
import { useSelector } from 'react-redux'

const windowWidth = Dimensions.get('window').width;

const location = () => {
  const navigation = useNavigation()
  const repository = useSelector(state => state)
  let text = `Olá! Me chamo ${repository.associate[0].nome}, portador do CPF *${repository.associate[0].cpf}*\nvenho através do aplicativo da Rio Ben e gostaria de solicitar a senha para o rastreio do meu veículo.` 
  let text_manutencao = `Olá! Me chamo ${repository.associate[0].nome}, portador do CPF *${repository.associate[0].cpf}*\nvenho através do aplicativo da Rio Ben e gostaria de solicitar a manutenção do meu equipamento.` 
  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.header}>
          <Image source={require('./../../assets/rastreamento.png')} style={styles.imageHeader} resizeMode="cover" />

          <View style={[styles.numbers, { textAlign: "center", marginTop: 50, marginBottom: 50 }]}>
            <FontAwesome5 name="satellite" size={36} color="black" />
            <Text style={{ textAlign: "center", fontSize: 16}}> {`Acesse o rastreador pelo novo portal.\nClique no botão abaixo`}</Text>
            <TouchableOpacity style={[styles.callButton, { backgroundColor: "#023b76", marginTop: 25, flexDirection: "row"  }]}  onPress={() => navigation.navigate(`NovoPortalRastreador`)}>
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: "center"}}> Entrar pelo novo portal</Text>
            </TouchableOpacity>
          </View>

          <View style={[styles.numbers, { textAlign: "center", marginTop: 50, marginBottom: 50 }]}>
            <FontAwesome5 name="car" size={36} color="black" />
            <Text style={{ textAlign: "center", fontSize: 16}}> {`Acesse o rastreador pelo antigo portal.\nClique no botão abaixo`}</Text>
            <TouchableOpacity style={[styles.callButton, { backgroundColor: "#023b76", marginTop: 25, flexDirection: "row"  }]}  onPress={() => navigation.navigate(`OlderPortalRastreador`)}>
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: "center"}}> Entrar pelo portal antigo</Text>
            </TouchableOpacity>
          </View>


          <View style={[styles.numbers, { textAlign: "center", marginTop: 50, marginBottom: 50 }]}>
            <FontAwesome5 name="satellite" size={36} color="black" />
            <Text style={{ textAlign: "center", fontSize: 16}}> {`Precisa rastrear seu carro?\nClique no botão abaixo`}</Text>
            <TouchableOpacity style={[styles.callButton, { backgroundColor: "#27ae60", marginTop: 25, flexDirection: "row"  }]}  onPress={() => Linking.openURL(`whatsapp://send?text=${text}&phone=5521996764569`)}>
              <FontAwesome5 name="whatsapp" size={24} color="#fff" />
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: "center"}}> Solicitar senha</Text>
            </TouchableOpacity>
          </View>

          <View style={[styles.numbers, { textAlign: "center", marginTop: 50, marginBottom: 50 }]}>
            <FontAwesome5 name="cog" size={36} color="black" />
            <Text style={{ textAlign: "center", fontSize: 16}}> {`Precisa de manutenção no rastreador?\nClique no botão abaixo`}</Text>
            <TouchableOpacity style={[styles.callButton, { backgroundColor: "#27ae60", marginTop: 25, flexDirection: "row"  }]}  onPress={() => Linking.openURL(`whatsapp://send?text=${text_manutencao}&phone=5521999464549`)}>
              <FontAwesome5 name="whatsapp" size={24} color="#fff" />
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: "center"}}> Solicitar manutenção</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View> 
    </ScrollView> 
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  imageHeader: {
    width: windowWidth,
    height: 210,
  },
  callButton: {
    justifyContent: "center",
    alignItems: "center",

    marginTop: 10,
    paddingHorizontal: 30,
    paddingVertical: 12,

    width: 200,
    height: 60,

    borderRadius: 70,
    alignSelf: "center",

    backgroundColor: "#7db0fe"
  },
  numbers: {
    paddingVertical: 40, 

    alignItems: "center",
    
    borderWidth: 1, 
    borderColor: "#f8f8f8",
    borderRadius: 30, 
    elevation: 2,

    backgroundColor: "#fff",
    
    width: 300,

    alignSelf: "center"
  }
})

export default location;