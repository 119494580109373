import React, { useState } from 'react';
import { Keyboard, View, ImageBackground, StyleSheet, Text, Image, TouchableOpacity, ActivityIndicator, Alert, Switch } from 'react-native';
import axios from 'axios'

import { AntDesign } from '@expo/vector-icons';

import { TextInputMask } from 'react-native-masked-text'
import { useNavigation } from '@react-navigation/native'

import { useSelector, useDispatch } from 'react-redux'


const login = (props) => {
    const navigation = useNavigation()
    const [cpfValue, setCpf] = useState('')
    const [load, setLoad] = useState(false)

    const associate = useSelector(state => state.associate)
    
    const [isEnabled, setIsEnabled] = useState(false);
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);
    
    const dispatch = useDispatch()

    const onChangeText = (text) => {
        setCpf(text)

        let l = text.length

        if(isEnabled && l == 18){
            Keyboard.dismiss()
        }else if(!isEnabled && l == 14){
            Keyboard.dismiss()
        }
    }

    const onPress = async () => { 
        setLoad(true)
        //put function to login here
        // http://192.168.1.150:3333

        let righCode = cpfValue.replace(/\D+/g, '')
        const uri = `https://api-gestaogma.herokuapp.com/associate/${righCode}`;
        console.log(uri)
        
        axios.get(uri)
        .then( async (response) => {
            const data = await response.data

            if(data == ""){
                await Alert.alert('CPF Invalido.\n\n Tente novamente.')
                setLoad(false)
            }else {
                if( (data.length > 0) ){
                    if(data[0].descricao_situacao == "ATIVO"){
                        console.log(data[1])
                        await dispatch({type: 'ADD_ASSOCIATE', associate: data })
                        await setLoad(false)
                        navigation.navigate('Tab')
                    }else{
                        Alert.alert('Erro ao entrar', 'O seu cadastro não está ativo em nossa associação.\n\n Entre em contato através do telefone (21) 2421-1608 para mais informações.')
                        setLoad(false)
                    }
                }
            }
        })
        .catch((error)=> {console.log(error)})
    }
    return (
        <View style={styles.container}>
            <ImageBackground style={styles.imageBack} source={require('./../../assets/imageBackground.png')}>
                <View style={[styles.logBox, {marginTop: 110}]}>
                    <View style={styles.imageBox}> 
                        <Image source={require('./../../assets/rioben_logo.png')} style={{width: 230, height: 60 }}/> 
                    </View>
                    <AntDesign name="user" size={48} color="#003b76" />
                    <Text style={{textAlign: "center", color: "#003b76", fontWeight: "bold"}}>{`Faça o login com\no seu CPF ou CNPJ`}</Text>
                    
                    <TextInputMask 
                        type={'cpf'}
                        value={cpfValue} 
                        onChangeText={(text) => onChangeText(text)} 
                        style={{width: 180, height: 40, marginTop: 20, paddingVertical: 8, borderBottomWidth: 3, borderColor: "#003b76", display: isEnabled ? "none" : "flex" }}
                        placeholder="___.___.___-__"
                    />

                    <TextInputMask 
                        type={'cnpj'}
                        value={cpfValue} 
                        onChangeText={(text) => onChangeText(text)} 
                        style={{width: 180, height: 40, marginTop: 20, paddingVertical: 8, borderBottomWidth: 3, borderColor: "#003b76", display: isEnabled ? "flex" : "none"}}
                        placeholder="__.___.___/____-__"
                    />

                    <View style={{marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Clique no botão para utilizar CNPJ</Text>
                        <Switch
                            trackColor={{ false: "#767577", true: "#2ecc71" }}
                            thumbColor={isEnabled ? "#fff" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleSwitch}
                            value={isEnabled}
                            style={{marginTop: 10}}
                        />
                    </View>

                    <ActivityIndicator size="large" color="#0000ff" style={{marginTop: 20}} animating={load}/>
                    <TouchableOpacity style={styles.enterButton} onPress={onPress}>
                        <Text style={{color: "#fff"}}>Entrar</Text>
                    </TouchableOpacity>
                </View>
            </ImageBackground>
        </View>
    );
}



const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    imageBack: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        alignItems: 'center'
    },
    imageBox: {
        marginBottom: 30
    },
    logBox: {
        width: 320,
        paddingVertical: 25,
        borderRadius: 30,
        elevation: 2,
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center"
    },
    enterButton : { 
        backgroundColor: "#003b76",
        marginTop: 20,
        padding: 12,
        paddingHorizontal: 36,
        borderRadius: 36
    }
})

export default login;