import React, { useState } from 'react';
import { View, ImageBackground, StyleSheet, Text, Image, TouchableOpacity, Button, Linking } from 'react-native';

import { useNavigation } from '@react-navigation/native'

const initialScreen = (props) => {

    const navigation = useNavigation()
    const onPressQuote = () => {
        navigation.navigate('Quote')
    }

    return (
        <View style={styles.container}>
            <ImageBackground style={styles.imageBack} source={require('./../../assets/tela-inicial.png')}>
                <View style={{position: 'absolute', bottom: 50}}>
                    <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.quoteButton}>
                            <Text style={{fontWeight: 'bold', textAlign: 'center'}}>ENTRAR</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.navigate('Quote')} style={{marginVertical: 15, marginBottom: 100, paddingVertical: 10, paddingHorizontal: 10, borderWidth: 1, borderColor: '#fff', borderRadius: 15}}>
                        <Text style={{color: '#fff', textAlign: 'center'}}>Ainda não sou associado</Text>
                    </TouchableOpacity>
                    <View style={[styles.container, {marginBottom: 30, justifyContent: 'center', alignItems: 'center', alignContent: 'center'}]}>
                        <Text style={{color: '#fff', fontWeight: 'bold', textAlign: 'center', paddingHorizontal: 20}} onPress={() => Linking.openURL('tel:02124211608')} >
                            Precisa de ajuda? Ligue (21) 2421-1608.
                        </Text>
                    </View>
                </View>
            </ImageBackground>
        </View>
    );
}



const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    imageBack: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        alignItems: 'center'
    },
    quoteButton: {
        backgroundColor: '#fff',
        color: '#003b76',
        marginTop: 50,
        paddingHorizontal: 15,
        paddingVertical: 20,
        borderRadius: 10
    }
})

export default initialScreen;