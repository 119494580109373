import React from 'react';
import { Provider } from 'react-redux'

import { createStore } from 'redux'
import reducers from './src/reducers'

import StackNavigator from './src/components/StackNavigator'


const store = createStore(reducers)

const container = () => (
    <Provider store={store}> 
        <StackNavigator />   
    </Provider>
)

export default container 