import React, { useState, useEffect} from 'react';
import { ScrollView, View, StyleSheet, Text, Dimensions, Image, Picker, ActivityIndicator } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

import axios from 'axios'

import { useSelector, useDispatch } from 'react-redux'

const windowWidth = Dimensions.get('window').width;

const benefits = ({route}) => {
  const { placaCarro } = route.params;
  const completeRepository = useSelector(state => state) 
  const associate =  useSelector(state => state.associate)
  const vehicles =  useSelector(state => state.associate[0].veiculos)
  const [load, setLoad] = useState(true)

  const dispatch = useDispatch()

  const [selectedValue, setSelectedValue] = useState(placaCarro);
  

  function validBen(ben){
    return ben != "CLUBE DE VANTAGENS"
  }

  useEffect(() => {
    const config = { 
      headers: { 
        "token": associate[1].token_usuario
      }
    }
    const uri = `https://api-gestaogma.herokuapp.com/vehicles/benefits/${selectedValue}`;
    axios.get(uri, config)
    .then(async (response) => { 
      await dispatch({type: 'ADD_BENEFITS', benefits: response.data })
      setLoad(false)
    })
    .catch((error)=> {console.log(error)})
  }, [selectedValue]);


  return ( 
    <ScrollView style={styles.container}>
      <View style={{justifyContent: "flex-start", alignItems: "center"}}>
        <View style={styles.header}>
          <Image source={require('./../../assets/beneficios.png')} style={styles.imageHeader} resizeMode="cover" />
        </View>

        <ActivityIndicator size="large" color="#0000ff" animating={load}/>

        <View style={styles.main}>
          <Text style={{ textAlign: "center", fontSize: 16,}}>{`Você possui os seguintes\nbenefícios para veiculos ativos`}</Text>
          <View style={{backgroundColor: '#89aff8', paddingBottom: 15, paddingTop: 15}}> 
            <Text style={{textAlign: 'center', color: 'color: #000', fontWeight: 'bold'}}>PLACA DO VEÍCULO: {selectedValue}</Text>
          </View>
          {/* 
          <Picker selectedValue={selectedValue} style={{ height: 50, width: 150, borderWidth: 2, borderColor: "#7db0fe"}} onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}>
            { 
              vehicles.map((veiculo) => { 
                  if(veiculo.situacao != "ATIVO"){ 
                    return false; 
                  } else{ 
                    return <Picker.Item label={veiculo.placa} value={veiculo.placa} key={veiculo.placa} />
                  }
                }
              )}
          </Picker> 
          */}
          
          <View style={[styles.listOfBenefits, {width: windowWidth - 20, marginTop: 30, marginBottom: 50, backgroundColor: "#fff", paddingVertical: 25, paddingHorizontal: 20, borderRadius: 30, borderWidth: 2, borderColor: "#7db0fe", }]}>
            { 
              completeRepository.benefits.produtos.filter(product => {
                if(!product.descricao){
                  return ""
                }else if(product.descricao != "SEGURO DE VIDA P/ ACIDENTE PESSOAL" && product.descricao != "CLUBE DE VANTAGENS" && product.descricao != "DESCONTO EM MEDICAMENTOS (FARMÁCIA)"){
                  return product
                }
              }).map((product) => {
                  return( 
                    <View style={styles.benefit} key={product.codigo_produto}>
                      <AntDesign name="earth" size={14} color="#313131" style={{marginRight: 10, top: 4}}/>
                      <Text style={{fontSize: 12, width: 200, color: "#313131", textTransform: "capitalize"}}>{product.descricao}:</Text>
                      <Text style={{fontSize: 12, color: (product.situacao == "ATIVO") ? "#2ecc71" : "#d35400",  fontWeight: "bold", marginLeft: 20}}>{product.situacao}</Text>
                    </View>
                  )
              })
            }
          </View>
        </View>
      </View>
    </ScrollView> 
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    
  },
  imageHeader: {
    width: windowWidth,
    height: 210,
  },
  main: {
    marginTop: 50
  },
  listOfbenefits: {
    flexDirection: "column",
    justifyContent: "space-between",

    backgroundColor: "red", 
    elevation: 5
  },
  benefit:{
    flexDirection: "row",
    alignItems: "center",

    marginTop: 15,
  }
 
})

export default benefits;