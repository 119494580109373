import React from 'react';
import {ScrollView, View, StyleSheet, Text, Dimensions, Image, TouchableOpacity, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';

import { useSelector } from 'react-redux'

const windowWidth = Dimensions.get('window').width;

const twentyfourhours = () => {
  const repository = useSelector(state => state)
  let text = `Olá! Me chamo ${repository.associate[0].nome}, portador do CPF *${repository.associate[0].cpf}*\nvenho através do aplicativo da Rio Ben e gostaria de solicitar a senha para o rastreio do meu veículo.` 
  let text_assistencia = `Olá! Me chamo ${repository.associate[0].nome}, portador do CPF *${repository.associate[0].cpf}*\nvenho através do aplicativo da Rio Ben e gostaria ajuda para meu veículo.` 
  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.header}>
          <Image source={require('./../../assets/assistencia24h.png')} style={styles.imageHeader} resizeMode="cover" />
          <View style={[styles.numbers, { textAlign: "center", marginTop: 50, marginBottom: 50 }]}> 
            <AntDesign name="phone" size={36} color="#313131" style={{marginBottom: 20}}/>
            <Text style={{ textAlign: "center", fontSize: 16}}> {`Escolha Opção 1\npara Assistência 24h`}</Text>

            <TouchableOpacity style={styles.callButton} onPress={() => Linking.openURL(`tel:08007211616`)}>
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: 'center'}}> 
                <AntDesign name="phone" size={16} color="#fff" style={{right: 20}} />  0800 721 1616
              </Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.callButton} onPress={() => Linking.openURL(`tel:08007219060`)}>
              <Text style={{color: "#fff", fontWeight: "bold"}}> 
                <AntDesign name="phone" size={16} color="#fff" style={{right: 20}} />  0800 721 9060
              </Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.callButton} onPress={() => Linking.openURL(`tel:08007214630`)}>
              <Text style={{color: "#fff", fontWeight: "bold"}}> 
                <AntDesign name="phone" size={16} color="#fff" style={{right: 20}} />  0800 721 4630
              </Text>
            </TouchableOpacity>
          </View>
          
          <View style={[styles.numbers, { textAlign: "center", marginTop: 50, marginBottom: 50 }]}>
            <FontAwesome5 name="clock" size={36} color="black" />
            <Text style={{ textAlign: "center", fontSize: 16}}> {`Solicite atendimento\npelo WhatsApp para\nassistência 24h`}</Text>
            <TouchableOpacity style={[styles.callButton, { backgroundColor: "#27ae60", marginTop: 25, flexDirection: "row"}]}  onPress={() => Linking.openURL(`whatsapp://send?text=${text_assistencia}&phone=5521998598678`)}>
              <FontAwesome5 name="whatsapp" size={24} color="#fff" />
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: "center", justifyContent: "center", alignItems: "center"}}>Solicitar atendimento</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.callButton, { backgroundColor: "#27ae60", marginTop: 25, flexDirection: "row" }]}  onPress={() => Linking.openURL(`whatsapp://send?text=${text_assistencia}&phone=552130900860`)}>
              <FontAwesome5 name="whatsapp" size={24} color="#fff" />
              <Text style={{color: "#fff", fontWeight: "bold", textAlign: "center", justifyContent: "center", alignItems: "center"}}>2° opção de atendimento</Text>
            </TouchableOpacity>
          </View>

        </View>
      </View> 
    </ScrollView> 
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  imageHeader: {
    width: windowWidth,
    height: 210,
  },
  callButton: {
    justifyContent: "center",
    alignItems: "center",

    marginTop: 10,
    paddingHorizontal: 30,
    paddingVertical: 12,

    width: 200,
    height: 60,

    borderRadius: 70,
    alignSelf: "center",

    backgroundColor: "#003b76"
  },
  numbers: {
    paddingVertical: 40, 

    alignItems: "center",
    
    borderWidth: 1, 
    borderColor: "#f8f8f8",
    borderRadius: 30, 
    elevation: 2,

    backgroundColor: "#fff",
    
    width: 300,

    alignSelf: "center"
  }
})

export default twentyfourhours;