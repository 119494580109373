import React from 'react';
import {Text, View, Image, TouchableOpacity, StyleSheet, Share} from 'react-native'
import {useNavigation} from '@react-navigation/native'

const  Boleto =  ( { descricao_situacao, linha_digitavel, placas, data_vencimento, link } ) => {
    const navigation = useNavigation()
    function convertDate(data){
        let c = data.split('-');
        
        return c[2] + "/" + c[1] + "/" + c[0]
    }
        
    
    //Clipboard.setString(linha_digitavel)

    return (
        <TouchableOpacity
            onPress={ () => Share.share({message: linha_digitavel})} 
            style={ [ styles.row,  descricao_situacao == 'ABERTO' ? styles.backRed :  styles.backGreen ] }
            key={linha_digitavel}>
                <View>
                    <View>
                        <Text style={{color: '#fff', textAlign: 'center', padding: 16}}> Toque para copiar código </Text>
                    </View>
                </View>
                <View style={styles.item }>
                    <Image 
                        source={
                            { 
                                uri: `https://images.vexels.com/media/users/3/157862/isolated/preview/5fc76d9e8d748db3089a489cdd492d4b-barcode-scanning-icon-by-vexels.png`, 
                                width: 60, 
                                height: 40
                            }
                        } 
                    />
                    <View>
                        <Text>Status:   { descricao_situacao }</Text>
                        <Text>Venc.:    { convertDate(data_vencimento) }</Text>
                        <Text>Placa:    { placas }</Text>
                    </View>
                </View>
                <Text style={{padding: 16, textAlign: 'center', color: '#fff', fontWeight: 'bold'}}> 
                    { linha_digitavel } 
                </Text>



                <TouchableOpacity style={styles.botaoBillet} onPress={() => navigation.navigate("BoletoPdf", { url: link })}>
                    <Text style={{color: "#fff", fontWeight: 'bold'}}>Clique aqui para baixar</Text>
                </TouchableOpacity>
        </TouchableOpacity>
    )

} 


//style
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        paddingVertical: 0,
        backgroundColor: '#f3f3f3'
    }, 
    headerTitle: {
        color: '#fff',
        fontSize: 32,
        textShadowColor: '#d3d',
        textShadowOffset:{
            width:-1, 
            height: 1
        },
        textAlign: 'center',
        fontWeight: 'bold',
    },
    row: {
        alignSelf: 'center',
        
        flexDirection: 'column',

        width: 360,

        marginVertical: 20,
        paddingHorizontal: 20,
        paddingVertical: 20,
        elevation: 1,


        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: '#fff',

        borderRadius: 10
    },
    backRed: {
        backgroundColor: '#e74c3c'
    },
    backGreen: {
        backgroundColor: '#2ecc71'
    },
    item: {
        flexDirection: 'row'
    }, 
    title: {
        fontWeight:'bold',
        alignSelf: 'center',
        fontSize: 26,
        marginTop: 50
    },
    botaoBillet: { 
        backgroundColor: "#7db0fe", 
        paddingVertical: 12,
        paddingHorizontal: 24, 
        borderRadius: 10
    }
});



export default Boleto