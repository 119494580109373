import React from 'react';
import { Text, StyleSheet, Platform } from 'react-native'
import { AntDesign, FontAwesome, Image } from '@expo/vector-icons';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
const BottomTab =  createBottomTabNavigator();

import home from './../pages/home'
import vehicle from './../pages/vehicle'
import twentyfourhours from './../pages/twentyfourhours'
import location from './../pages/location'
import vantagens from './../pages/vantagens'

const BottomNavigator = () => {
    const platform = Platform.OS

  return (
    <>
        <BottomTab.Navigator>
            <BottomTab.Screen 
                name="Início"
                component={home}
                options={
                    {
                        tabBarLabel:  ( ({ focused  }) => <Text style={[styles.titlebar, { color: focused ? "#003b76" : "#313131" }]}>Início</Text>),
                        tabBarIcon:   ( ({ focused  }) => <AntDesign name="home" size={24} color={focused ? "#003b76" : "#313131"} />)
                    }
                }
            />

            <BottomTab.Screen 
                name="Veículos"
                component={vehicle}
                options={
                    {
                        tabBarLabel:  ( ({ focused  }) => <Text style={[styles.titlebar, { color: focused ? "#003b76" : "#313131" }]}>2° via</Text>),
                        tabBarIcon:   ( ({ focused  }) => <AntDesign name="barcode" size={24} color={focused ? "#003b76" : "#313131"} />)
                    }
                }
            />

            <BottomTab.Screen 
                name="Vantagens"
                component={vantagens}
                options={
                    {
                        tabBarLabel:  ( ({ focused  }) => <Text style={[styles.titlebar, { color: focused ? "#003b76" : "#313131" }]}>Vantagens</Text>),
                        tabBarIcon:   ( ({ focused  }) => <AntDesign name="gift" size={24} color={focused ? "#003b76" : "#313131"} />)
                    }
                }
            />

             <BottomTab.Screen 
                name="Assist"
                component={twentyfourhours}
                options={
                    {
                        tabBarLabel:  ( ({ focused  }) => <Text style={[styles.titlebar, { color: focused ? "#003b76" : "#313131" }]}>Assistência</Text>),
                        tabBarIcon:   ( ({ focused  }) => <AntDesign name="phone" size={24} color={focused ? "#003b76" : "#313131"} />)
                    }
                }
            />

            <BottomTab.Screen 
                name="Rastreamento"
                component={location}
                options={
                    {
                        tabBarLabel:  ( ({ focused  }) => <Text style={[styles.titlebar, { color: focused ? "#003b76" : "#313131" }]}>Rastreamento</Text>),
                        tabBarIcon:   ( ({ focused  }) => <AntDesign name="earth" size={24} color={focused ? "#003b76" : "#313131"} />)
                    }
                }
            />
        </BottomTab.Navigator>
    </>  
  );
}

const styles = StyleSheet.create({
    titlebar: {
      fontSize: 10
    }
})
  

export default BottomNavigator;